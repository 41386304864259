<template>
  <div >
    <form ref='order_info' name='order_info'>
      <!-- 주문정보 세팅 -->
      <input type='hidden' name='ordr_idxx' :value='paymentParams.orderId' maxlength='40' />
      <input type='hidden' name='good_name' :value='nhnkcpRequestPayName' />
      <input type='hidden' name='good_mny'  :value='paymentParams.amount' maxlength='9' />
      <input type='hidden' name='currency'  :value='paymentParams.currency' maxlength='9' />
      <input type='hidden' name='buyr_name' :value='paymentParams.customerName' />
      <input type='hidden' name='buyr_tel2' :value='paymentParams.customerPhoneNumber' />
      <!-- 신용카드 -->
      <input type='hidden' name='pay_method' value='100000000000' />
      <!-- 가맹점 정보 설정-->
      <input type='hidden' name='site_cd'   :value='nhnkcpSiteCd' />
      <input type='hidden' name='site_name' :value='showingEventName' />
      <input type='hidden' name='site_key'  :value='nhnkcpSiteKey'>
      <input type='hidden' name='eng_flag' :value='nhnkcpEngFlag'>
      <!-- 인증데이터 처리-->
      <input v-if='!isKorean' type='hidden' name='keyin' value='KEYIN'> 
      <input type='hidden' name='res_cd' value='' />
      <input type='hidden' name='res_msg' value='' />
      <input type='hidden' name='enc_info' value='' />
      <input type='hidden' name='enc_data' value='' />
      <input type='hidden' name='ret_pay_method' value='' />
      <input type='hidden' name='tran_cd' value='' />
      <input type='hidden' name='use_pay_method' value='' />
    </form>
  </div>
</template>

<!-- <script type="text/javascript" src="https://pay.kcp.co.kr/plugin/payplus_web.jsp"></script> -->
<script type="text/javascript" src="https://testpay.kcp.co.kr/plugin/payplus_web.jsp"></script>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NhnkcpPayment',
  props: [
    'paymentParamsProp',
    'isModalPayment',
    'isSingleOrder',
    'isKorean',
  ],
  computed: {
    ...mapGetters('events', [
      'showingEventName',
      'showingEventRegistrationConfigurations',
    ]),
    nhnkcpRequestPayName () {
      return (this.showingEventRegistrationConfigurations  && this.showingEventRegistrationConfigurations.nhnkcpRequestPayName) ? this.showingEventRegistrationConfigurations.nhnkcpRequestPayName : 'Registration Fee'
    },
    hasPaymentData () {
      if (this.isModalPayment) {
        return this.hasPaymentParamsProp
      } else {
        return this.hasQueryParams
      }
    },
    hasPaymentParamsProp () {
      return this.paymentParamsProp && 
             this.paymentParamsProp.pg &&
             this.paymentParamsProp.currency &&
             this.paymentParamsProp.amount &&
             this.paymentParamsProp.orderId &&
             this.paymentParamsProp.orderName &&
             this.paymentParamsProp.customerName && 
             this.paymentParamsProp.customerPhoneNumber
    },
    hasQueryParams () {
      return this.$route.query.pg &&
             this.$route.query.currency &&
             this.$route.query.amount &&
             this.$route.query.orderId &&
             this.$route.query.orderName &&
             this.$route.query.customerName
    },
    paymentParams () {
      let params = null
      if (this.isModalPayment) {
        params = this.paymentParamsProp
      } else {
        params = {
          pg:           this.$route.query.pg,
          currency:     this.$route.query.currency,
          amount:       this.$route.query.amount,
          orderId:      this.$route.query.orderId,
          orderName:    this.$route.query.orderName,
          customerName: this.$route.query.customerName,
          customerPhoneNumber: this.$route.query.customerPhoneNumber,
        }
      }
      params.amount = params.currency === 'USD' ? parseInt(params.amount) * 100 : parseInt(params.amount)
      return params
    },
    nhnkcpSiteCd () {
      return this.isKorean ? process.env.VUE_APP_ICOLA_NHNKCP_PG_SITE_CD_DOMESTIC : process.env.VUE_APP_ICOLA_NHNKCP_PG_SITE_CD_OVERSEA
    },
    nhnkcpSiteKey () {
      return this.isKorean ? process.env.VUE_APP_ICOLA_NHNKCP_PG_SITE_KEY_DOMESTIC : process.env.VUE_APP_ICOLA_NHNKCP_PG_SITE_KEY_OVERSEA
    },
    nhnkcpEngFlag () {
      return this.isKorean ? 'N' : 'Y'
    },
    paymentFailMessage () {
      return this.isKorean ? '결제가 정상적으로 처리되지 않았습니다.' : 'Your payment could not be processed.'
    },
  },
  methods: {
    initNhnkcpPayment () {
      if (this.hasPaymentData) {
        KCP_Pay_Execute(this.$refs.order_info)
      } else {
        this.handlePaymentFail()
      }
    },
    handlePaymentFail () {
      this.$alert(this.paymentFailMessage, {
        type: 'warning'
      }).then(() => {
        if (this.isModalPayment) {
          this.$emit('payment-fail', this.paymentParams.orderId)
        } else {
          this.$router.push({name: 'PaymentFail', query: {orderId: this.paymentParams.orderId}})
        }
      })
    }
  },
  mounted () {
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'empty-page')
    }
    document.addEventListener('nhnckp-payment-ready', (e) => {
      let successParams = {
        pg: 'nhnkcp',
        tranCd: e.detail.tranCd,
        encData: e.detail.encData,
        encInfo: e.detail.encInfo,
        orderId: this.paymentParams.orderId,
      }
      if (this.isModalPayment) {
        this.$emit('payment-success', successParams)
      } else {
        // not using now
        this.$router.push({name: 'PaymentSuccess', query: successParams})
      }
    })
    document.addEventListener('nhnckp-payment-cancel', (e) => {
      if (this.isModalPayment) {
        this.$emit('payment-fail', this.paymentParams.orderId)
      } else {
        this.$router.push({name: 'PaymentFail', query: {orderId: this.paymentParams.orderId}})
      }
    })
    this.initNhnkcpPayment()
  },
  beforeDestroy () {
    if (!this.isModalPayment) {
      this.$emit('update:layout', 'default-layout')
    }
  },
}
</script>
